import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import BgImage from '../images/brand-list.jpg'
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton";
import { StaticImage } from "gatsby-plugin-image"
import BreakpointDown from "../components/Media/BreakpointDown";


const SectionBgAfter = styled(Section)`
 &:after{
   right:0;
   left:0;
   background-size:cover;
 }
 .container{
   z-index:1;
 }
`
const PageTitle = styled.h1`
 margin-bottom:0;
 text-transform: capitalize;
 text-align:center;
`
const BrandLogo = styled.div`
 position:relative;
 margin-left:auto;
 margin-right:auto;
 max-width:200px;
 margin-top:10px;
`
const PageTitleWrapper = styled.div`
text-align:center;
font-size:16px;
img{ margin-top:10px;}
p{ margin-bottom:0;}
`
const SectionTitle = styled.h2`
 margin-bottom:0;
`
const BrandWrapper = styled.div`
  &+&{
    margin-top:100px;
    ${BreakpointDown.md`
     margin-top:40px;
    `}
  }
`
// blog Card 

const BrandRow = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-left:-20px;
 margin-right:-20px;
 ${BreakpointDown.lg`
  margin-left:-15px;
  margin-right:-15px;
 `}

`
const BrandColumn = styled.div`
 flex:0 0 100%;
 width:100%;
 padding-left:20px;
 padding-right:20px;
 margin-top:40px;
 ${BreakpointDown.lg`
   padding-left:15px;
 padding-right:15px;
 `}
 ${BreakpointDown.md`
  margin-top:30px;
 `}
`

const Card = styled.div`
  padding: 70px 62px;
  background:#fff;
  display:flex;
  height:100%;
  align-items:center;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  @media(max-width:1440px){
    padding: 15px;
  }
  ${BreakpointDown.md`
   text-align:center;
   flex-wrap:wrap; 
 `}
  .btn{
    margin-top:0px;
  }
`
const CardTitle = styled(Link)`
 margin-bottom:6px;
`
const CardImg = styled.div`
  position: relative;
  max-width:200px;
  width:100%;
  margin-top:26px;
  ${BreakpointDown.md`
     max-width:200px;
     margin-top:0px;
     margin-left:auto;
     margin-right:auto; 
  `}
  .gatsby-image-wrapper{ 
    width:100%;
  }
`
const BrnadDesciption = styled.div`
  display:flex;
  flex-direction:column;
  padding:30px 30px 30px 130px;
${BreakpointDown.lg`
   padding:15px;
`}
${BreakpointDown.md`
   padding:0;
   margin-top:15px;
`}
p{ font-size:16px;}
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3; 
`
function BrandListPage() {
  return (
    <Layout pageName="brand-list">
      <SEO
        title="CISCO: Networking Equipment Solutions for Businesses"
        description="WYN Technologies offer CISCO Networking Equipment Solutions such as data centers, cloud technologies, and internet security for businesses of all sizes."/>
      <SectionBgAfter pt="156px" pb="100px" xpt="90px" xpb="60px" mpt="60px" mpb="40px" bgAfter={`url(${BgImage})`}>
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link> <Link to="/#">cisco</Link>
          </BreadCrumb>
          <PageTitle>CISCO: Networking Equipment Solutions</PageTitle>
          <BrandLogo>
            <StaticImage
              src="../images/brand-1.png"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Letast Article"
              placeholder="blurred"
            />
          </BrandLogo>
          <p><strong>CISCO</strong> has maintained its focus on solving business challenges since its founding in 1984. A husband and wife wanted to be able to email each other from their respective offices at Stanford University, but the technological shortcomings of the day prevented it. Thankfully they were able to solve the challenge, the multiprotocol router was born, and what is now known as the internet was essentially born. Today, CISCO continues to seize the opportunities of tomorrow by proving that amazing things can happen whenever you connect the unconnected.</p>
        </div>
      </SectionBgAfter>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">Why CISCO?</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                <p>Based in San Jose, CA, CISCO continues to innovate and push the boundaries of enterprise networks, data centers, cloud technologies, internet security, the business of IT, the future of work, and collaboration in general. CISCO has also made an organizational commitment to positively impact one billion people by 2025, through continuing to develop and provide various types of technology for building bridges.</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>

      {/* <Section pt="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8" pb="100px" xpb="60px">
        <div className="container">
          <BrandWrapper>
            <PageTitleWrapper>
              <SectionTitle className="h1">Products</SectionTitle>
              <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum been industry’s standard.</p>
            </PageTitleWrapper>
            <BrandRow>
             
                <BrandColumn>
                  <Card>
                    <CardImg>
                      <StaticImage
                        src="../images/phone-reciever.png"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <BrnadDesciption>
                      <CardTitle to="/brand-detail" className="h5">ZIP 43GExceptional Value in a Feature Rich IP Phone</CardTitle>
                      <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.</p>
                      <Link to="/brand-detail"><PrimaryLinkButton text="View Full Detail" /></Link>
                    </BrnadDesciption>
                  </Card>
                </BrandColumn>
            
            </BrandRow>
          </BrandWrapper>
         <BrandWrapper>
            <PageTitleWrapper>
              <SectionTitle to="/brand-detail">IP Phone Systems</SectionTitle>
              <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum been industry’s standard.</p>
            </PageTitleWrapper>
            <BrandRow>
             
                <BrandColumn>
                  <Card>
                    <CardImg>
                      <StaticImage
                        src="../images/phone-reciever.png"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <BrnadDesciption>
                      <CardTitle to="/brand-detail" className="h5">ZIP 43GExceptional Value in a Feature Rich IP Phone</CardTitle>
                      <p>The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.</p>
                      <Link to="/brand-detail"><PrimaryLinkButton text="View Full Detail" /></Link>
                    </BrnadDesciption>
                  </Card>
                </BrandColumn>
            
            </BrandRow>
          </BrandWrapper>
        </div> 
      </Section>*/}
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px" bgColor="rgb(248, 248, 248)">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">A Few Examples of CISCO Products We Install & Configure at WYN Technologies</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
              <MarkdownContent>
                <h3>DATA CENTER NETWORKING</h3>
                <p>CISCO offers data center networking solutions built for scale with industry-leading automation, programmability, and real-time visibility. These solutions save time and resources by automating one policy across the network. Improve your Day 2 operations, experience high performance at scale, and enjoy extensive network management capabilities.</p>
                 <ul>
                  <li>
                    <strong>Data center switching</strong>
                  </li>
                  <li>
                    <strong>LAN/SAN management</strong>
                   </li>
                  <li>
                    <strong>Hybrid cloud management</strong>
                   </li>
                  <li>
                    <strong>Multicloud networking</strong>
                   </li>
                  <li>
                    <strong>Storage networking</strong>
                    </li>
                </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h3>ACCESS NETWORKING</h3>
                <p>With access networking solutions from CISCO, you can reimagine your connections, reinforce security, and redefine the IT experience. Create a pervasive environment for people and things to roam and connect. Integrate identity-based trusted access for any users and devices to any data and applications. Help ensure quality of experience through observability and advanced AI and insights.</p>
                <ul>
                  <li>
                    <strong>CISCO Access Networking</strong>
                    <p>Access Networking is a portfolio of centrally managed CISCO Catalyst® switches, wireless solutions, and software powered by advanced AI/ML. It allows businesses and organizations to reimagine their applications, empower their teams, secure their data, and transform their infrastructure.</p>
                  </li>
                  <li>
                    <strong>CISCO Catalyst 9000</strong>
                    <p>Cisco Catalyst 9000-Series access points are adaptive, fast, intelligent, and secure. Not only do they adhere to the Wi-Fi 6 (802.11ax) standard, but they go well above and beyond, too. These access points allow for more flexibility and scalability needed to handle the extra devices connected to the network, and the data that they will generate. From the 9100 Series all the way up to the 9800 Series, there’s a bandwidth and speed level to meet every business need.</p>
                   </li>
                   </ul>
                </MarkdownContent>
                <h3>ROUTERS</h3>
                <p>With CISCO routers, you can seamlessly and securely connect applications across your cloud, data center, and edge locations. Proven router solutions for businesses and organizations of all sizes and scopes!</p>
                 <ul>
                  <li>
                    <strong>Branch routers</strong>
                    <p>Gain highly secure connectivity, machine learning, and cloud-managed security through CISCO’s Catalyst 8300 Series, 8200 Series, 8200 Series Edge, ISR 4000 Series, and ISE 100 Series.</p>
                  </li>
                  <li>
                    <strong>Cloud connectors</strong>
                    <p>Pre-terminated copper assemblies include trunk assemblies, pigtails, and commercial-grade or industrial copper patch cords. Offering superior quality and performance, the robust design of these assemblies means they’re able to withstand all the rigors of daily use. Copper pre-term assemblies are available in Category 5e, 6, and 6A shielded and unshielded options. Unique versions also include traceable patch cords, and trunk assemblies in various bundle sizes.</p>
                  </li>
                  <li>
                    <strong>Cloud edge</strong>
                    <p>Enhance multi-cloud application experiences across your cloud, data center, and WAN edge with the CISCO Catalyst 8000 Edge Platforms Family of products.</p>
                  </li>
                  <li>
                    <strong>Industrial routers and gateways</strong>
                    <p>Deliver enterprise-class features in rugged and harsh environments with these industrial solutions from CISCO, including Catalyst IR1100 Rugged, Catalyst IR1800 Rugged, Catalyst IR8100 Heavy Duty, Catalyst IR8300 Rugged, and IoT Gateways.</p>
                  </li>
                  <li>
                    <strong>Small business routers</strong>
                    <p>Get advanced capabilities and security features for your small business, all at a more affordable price point. Includes the ISR 1000 Series, ISR 900 Series, Small Business RV Series, and Meraki MX Security Appliances.</p>
                  </li>
                <li>
                    <strong>Virtual routers</strong>
                    <p>Get multi-tenant network services for public, private, or provider-hosted clouds with CISCO virtual router solutions, including IOS XRv 9000, Catalyst 8000V, and CSR 1000V.</p>
                  </li>
                <li>
                    <strong>WAN aggregation and internet edge routers</strong>
                    <p>Transform your service provider or enterprise network edge for the digital era with CISCO’s NCS 500 Series, NCS 5000 Series, NCS 5500 Series, NCS 5700 Series, Catalyst 8500 Series, ASR 900 Series, and ASR 1000 Series.</p>
                  </li>
                   <li>
                    <strong>Wireless WAN</strong>
                    <p>CISCO Catalyst Cellular Gateways provide multigigabit 5G SD-WAN links. Connect to the cloud using sub-6 GHz 5G (mid-band) and Cisco 4G LTE Advanced Pro technologies. Cisco SD-WAN also enables remote management through the easy-to-use vManage dashboard.</p>
                  </li>
                </ul>
                </MarkdownContent>
                <MarkdownContent>
                <h3>WIRELESS</h3>
                <p>Wi-Fi 6 is revolutionizing the wireless experience, both indoors and outdoors. Are you ready to take advantage? If so, CISCO can help you get where you need to be.</p>
                 <ul>
                  <li>
                    <strong>Access points</strong>
                    <p>Update your wireless network with Cisco Catalyst Wi-Fi 6 access points, featuring the Catalyst 9100 Access Points family of products.</p>
                  </li>
                  <li>
                    <strong>Antennas</strong>
                    <p>With directional and omnidirectional antennas, low-loss cable, mounting hardware, and other accessories from CISCO, you can customize a perfect wireless solution that meets the requirements of even the most challenging applications.</p>
                  </li>
                  <li>
                    <strong>Outdoor and industrial wireless</strong>
                    <p>Deliver Wi-Fi 6 access to people, apps, and network resources outdoors, as well as in challenging industrial environments. Check out the CISCO Catalyst 9124 Series, Aironet 1540 Series, Aironet 1560 Series, Aironet 1570 Series, along with signature industrial wireless products.</p>
                  </li>
                  <li>
                    <strong>Traffic optimization</strong>
                    <p>Improve customer experience while reducing costs, time, effort, and end-to-end network congestion with CISCO Ultra Traffic Optimization (CUTO) and Ultra Services Proxy (CUSP).</p>
                  </li>
                  <li>
                    <strong>Wireless LAN controller</strong>
                    <p>Power your network with the CISCO AI/ML technology found in these intelligent controller products, including physical controllers, cloud controllers, embedded wireless, and controllerless solutions.</p>
                  </li>
                </ul>
                </MarkdownContent>
                 <MarkdownContent>
                <h3>SWITCHES</h3>
                <p>CISCO switches are always learning, adapting, and protecting. Build a foundation for digital transformation at your data center, core, or edge with these advanced switch products.</p>
                 <ul>
                  <li>
                    <strong>LAN access</strong>
                    <p>Strengthen security, and simplify operations with Catalyst 9400, Catalyst 9300, Catalyst 9200 Series, Catalyst 1000 Series, Meraki MS Series, and Catalyst PON Series LAN access.</p>
                  </li>
                  <li>
                    <strong>LAN compact</strong>
                    <p>These small, flexible switches can go where others can't. Check out CISCO LAN compact solutions including Catalyst 1000 Series, Catalyst Micro Switches, Catalyst Digital Building Series,Catalyst 3560-CX Series, and Meraki MS120-8.</p>
                  </li>
                  <li>
                    <strong>LAN core and distribution</strong>
                    <p>Enjoy top-of-the-line resiliency, scale, and services with CISCO LAN core and distribution products, including the Catalyst 9600 Series, Catalyst 9500 Series, Catalyst 9400 Series, and Meraki MS400 Series.</p>
                  </li>
                  <li>
                    <strong>Data center</strong>
                    <p>Meet the next-generation data center, powered by CISCO. Check out the Nexus 9000 Series, Nexus 3550 Series, and MDS 9000 class of products.</p>
                  </li>
                  <li>
                    <strong>Industrial ethernet</strong>
                    <p>Add scalable, secure switching to your industrial network with CISCO IE5000, CISCO IE4000, Catalyst IE3400 Heavy Duty Series, and Catalyst IE3400 Rugged Series.</p>
                  </li>
                  <li>
                    <strong>Small business</strong>
                    <p>Get full features with affordability, specially designed for the needs of growing small businesses. Have a look at the CISCO Business 250 Series, CISCO Business 350 Series, CISCO Business 110 Series, and Catalyst 1000 Series of products.</p>
                  </li>
                  </ul>
                </MarkdownContent>
             </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                <h2 className="h1">Got Questions? Ready to Get Started? WYN Technologies Is Here to Help!</h2>
              </MarkdownContentTitle>
              <MarkdownContent>
                 <p>Tell us about your business needs and goals, and <strong>WYN Technologies</strong> can suggest the right CISCO networking equipment product solutions. <strong>WYN</strong> can deliver Whatever You Need to address any business technology opportunities for your business or organization! Call us today at <a href="tel:3368990555">(336) 899-0555</a>.</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default BrandListPage
